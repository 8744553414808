<template lang="pug">
include ../../../configs/template
div.row
  div.col-12
    +textarea-validation('data.body.comment', 'comment', '["required", "alphaENText"]')
  div.col-12
    v-btn(color="error" @click="setReject") {{$t('setReject')}}
</template>
<script>
import { RejectForm } from '@/mixins/validationRules'
import { mapActions, mapGetters } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
export default {
  data () {
    return {
      data: {
        body: {
          comment: null,
          additional_info: {}
        }
      }
    }
  },
  validations () { return RejectForm() },
  computed: {
    ...mapGetters(['getDirectoryObject'])
  },
  methods: {
    ...mapActions(['uploadDigitalizationDocumentISCById']),
    async setReject () {
      if (this.$v.$invalid) return this.$v.$touch()
      const { statementId } = this.$route.params

      this.$swal({
        title: this.$t('warning'),
        text: this.$t('rejectingConfirmation', { status: this.getDirectoryObject({ id: STATUSES_DOCUMENT_CONSTANTS.rejectDigitalizationDocumentISC, value: 'statuses' }).name_ukr }),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.uploadDigitalizationDocumentISCById({
            id: statementId,
            body: { status_document: STATUSES_DOCUMENT_CONSTANTS.rejectDigitalizationDocumentISC },
            comments: this.data })
          if (response) {
            this.$parent.getData()
          }
          this.$v.$reset()
        }
      })
    }
  }
}
</script>
